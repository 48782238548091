.container {
    background-color: #ffffff;
    padding: 20px;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.center-div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-input {
    width: 100%;
}

.geofence-list-item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.geofence-management-button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.geofence-list-item-content-container {
    display: flex;
    justify-content: flex-start;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.address {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    font-size: 12px;
}
  
.address > * {
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
}

.datetime {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    font-size: 11px;
}

.POI {
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background-color: #5C1A8E;
    font-size: 10px;
}

.geofence-list-item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.geofence-list-item-content-container {
    display: flex;
    justify-content: flex-start;
    border: none;
    background-color: transparent;
    cursor: pointer;
}