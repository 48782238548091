.infoBox > img {
    visibility: hidden;
}

.timeline {
    position: absolute;
    top: 0;

    width: 20.5%;
    height: 100%;
    min-width: 310px;
}

.searchBar {
    position: absolute;
    top: 13.5px;
    right: 60px;

    width: 300px;
}

.controlPanel {
    position: absolute;
    top: 65px;
    right: 12px;

    width: 35px;
}

.ant-drawer-header {
    position: absolute;
    padding: 16px 24px;
    z-index: 99999;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
  }
  .ant-drawer-body {
     padding: 24px;
     margin-top: 3rem;
     font-size: 14px;
     line-height: 1.5;
     word-wrap: break-word;
   }
  